import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  Wrap,
} from "@chakra-ui/react"
import * as React from "react"
import { Link as GatsbyLink, graphql, navigate, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import Layout from "../components/layout"
import { AllBlogPosts, IBlogPost } from "../components/blogs"
import { IQueriedGatsbyImage } from "../components/utils"
import { SEO } from "@avldev/gatsby-theme-core-ui/tools"

interface BlogPageProps extends PageProps {
  data: {
    blogs: AllBlogPosts
    hero: IQueriedGatsbyImage
  }
}

interface UniformBoxProps extends BoxProps {
  children: React.ReactNode
}

function UniformBox(props: UniformBoxProps): JSX.Element {
  return <Box maxW="95vw" mx="auto" overflowY="auto" w="800px" {...props} />
}

let tagarray: string[] = []

function BlogPage({ data, location }: BlogPageProps): JSX.Element {
  const [relPubDates, setRelPubDates] = React.useState<string[] | []>([])
  const [blogs, setBlogs] = React.useState<IBlogPost[]>(data.blogs.nodes)
  const [blogFilters, setBlogFilters] = React.useState<string[]>([])
  const [blogTags] = React.useState<string[] | []>(
    data.blogs.nodes.reduce((acc, cur) => {
      const tags = cur.tags.filter((tag: string) => !acc.includes(tag))
      return [...acc, ...tags]
    }, tagarray)
  )

  React.useEffect(() => {
    dayjs.extend(relativeTime)

    const relativeDates = data.blogs.nodes.reduce((acc, cur) => {
      const publishedFromNow = dayjs(cur.datePublished).fromNow()
      return [...acc, publishedFromNow]
    }, [])

    setRelPubDates(relativeDates)
  }, [data.blogs])

  React.useEffect((): void => {
    if (blogFilters.length) {
      const newVisibleBlogs = blogs.filter(blog => {
        const tagsBeingFiltered = blog.tags.filter(tag =>
          blogFilters.includes(tag)
        )
        return tagsBeingFiltered.length === 0
      })
      setBlogs(newVisibleBlogs)
    } else {
      setBlogs(data.blogs.nodes)
    }
  }, [blogFilters])

  const toggleBlogFilter = (tagToToggle: string): void => {
    if (blogFilters.includes(tagToToggle)) {
      const newFilters = blogFilters.filter(tag => tag !== tagToToggle)
      setBlogFilters(newFilters)
    } else {
      setBlogFilters([...blogFilters, tagToToggle])
    }
  }

  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Have questions about the homebuilding process, but not sure where to find answers? Our informative blog posts might have the knowledge you seek.",
          image: data.hero.gatsbyImageData.images.fallback.src,
          imageAlt: data.hero.description,
        }}
        title="The Sundog Blog, Our Take On News and Trends | Sundog Homes"
        url={location.href}
      />
      <UniformBox h="400px" pt={6}>
        <Image
          alt={data.hero.description}
          as={GatsbyImage}
          h="95%"
          image={data.hero.gatsbyImageData}
          rounded="lg"
          shadow="md"
          w="100%"
        />
      </UniformBox>
      <UniformBox pb={6}>
        <Heading as="h1" fontWeight="extrabold" textAlign="center">
          The Sundog Blog
        </Heading>
      </UniformBox>
      <UniformBox mb={10} py={1}>
        <Flex justify="center" mb={2} w="100%">
          <Text fontSize="md" fontWeight="bold">
            Filter Blogs by Topic:
          </Text>
        </Flex>
        <Wrap align="center" justify="center" py={1}>
          {blogTags.map((tag: string, index: number) => {
            const isFiltered = blogFilters.includes(tag)
            return (
              <Tag
                colorScheme={isFiltered ? `gray` : `blue`}
                cursor="pointer"
                key={index}
                onClick={() => toggleBlogFilter(tag)}
                transitionDuration="0.1s"
                variant="subtle"
                _hover={{ bg: isFiltered ? `gray.200` : `blue.200` }}
              >
                <TagLabel>{tag}</TagLabel>
                <TagRightIcon as={isFiltered ? FaEyeSlash : FaEye} />
              </Tag>
            )
          })}
        </Wrap>
        {blogFilters.length > 0 && (
          <Flex justify="center" mt={1} w="100%">
            <Button onClick={() => setBlogFilters([])} variant="link">
              View All
            </Button>
          </Flex>
        )}
        <Wrap align="start" justify="start" mt={6} py={1}>
          {blogs.map((blog: IBlogPost, index: number) => {
            const blogSlug = `/blog/${blog.slug}/`
            return (
              <LinkBox
                as={Flex}
                border="1px"
                borderColor="gray.200"
                flexDir="column"
                justify="center"
                key={blog.slug}
                p={4}
                rounded="lg"
                w={{ base: `100%`, sm: `48%` }}
              >
                <Image
                  alt={blog.featuredImage.description}
                  as={GatsbyImage}
                  h="250px"
                  image={blog.featuredImage.gatsbyImageData}
                  p={4}
                  rounded="lg"
                  w="100%"
                />
                <LinkOverlay
                  as={GatsbyLink}
                  to={blogSlug}
                  _hover={{
                    color: `red.500`,
                    textDecoration: `underline`,
                  }}
                >
                  <Text fontSize="xl" fontWeight="bold" noOfLines={2} mt={2}>
                    {blog.title}
                  </Text>
                </LinkOverlay>
                <Text fontSize="lg" mt={2} noOfLines={5}>
                  {blog.content.childMarkdownRemark.excerpt}
                </Text>
                <Button
                  colorScheme="red"
                  d={{ md: `none` }}
                  isFullWidth
                  mt={4}
                  mx="auto"
                  onClick={() => navigate(blogSlug)}
                >
                  Read more
                </Button>
                <Text
                  color="gray.500"
                  fontSize="md"
                  mt={2}
                  textAlign={{ base: `center`, sm: `right` }}
                >
                  Posted {relPubDates[index]}
                </Text>
              </LinkBox>
            )
          })}
        </Wrap>
      </UniformBox>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query {
    blogs: allContentfulBlogPost(
      filter: { owner: { eq: "Sundog Homes" } }
      sort: { fields: datePublished, order: DESC }
    ) {
      nodes {
        ...BlogPostExcerpt
      }
    }
    hero: contentfulAsset(title: { eq: "bear-lake-home-2" }) {
      description
      gatsbyImageData(height: 400, width: 800)
    }
  }
`
